.icon-hover {
  transition: transform 0.3s ease, color 0.3s ease;
}

.icon-hover:hover {
  transform: scale(1.1);
}

a {
  color: inherit;
}
